import * as React from "react"

const BlogPostExcerpt = (props) => {
  return (
	<>
		<div className="my-4">
			<div className="hover:bg-zinc-200 border shadow-md bg-white drop-shadow-md">

				<div className="p-4 flex w-full items-center justify-between border-b-2 pb-3">
					<div className="flex items-center space-x-3">
						<div className="text-lg font-bold text-black">{props.post.frontmatter.title}</div>
					</div>

					<div className="flex items-center space-x-8">
						<div className="rounded-2xl border px-3 py-1 text-xs font-semibold">{props.post.frontmatter.tags}</div>
						<div className="text-xs text-bkack">{props.post.frontmatter.date}</div>
					</div>
				</div>

				<div className="p-4 border-l-red border-l-8">
					<div className="text-sm text-black">{props.post.excerpt}</div>
				</div>

			</div>
		</div>
	</>
	)
}

export default BlogPostExcerpt
