import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from "../components/layout"
import BlogPostExcerpt from '../components/blog-post-excerpt'
import { FaArrowLeft } from "@react-icons/all-files/fa/FaArrowLeft";
import SEO from "../components/seo";

export const query = graphql
`
query {
  allMarkdownRemark(
    sort: {fields: [frontmatter___date], order: DESC}
    filter: {frontmatter: {published: {eq: true}}}
  ) {
    edges {
      node {
        id
        excerpt(pruneLength: 250)
        tableOfContents
        frontmatter {
          date(formatString: "MMM DD, YYYY")
          author
          title
          slug
          tags
        }
      }
    }
  }
}
`

const PostsPage = ({ data }) => {
  const posts = data.allMarkdownRemark.edges;
  return (
    <Layout>
      <SEO title="Posts" />

      <Link to='/'>
        <div className='flex flex-row items-center'>
          <FaArrowLeft className='p-2' size={50} />
          <h3 className='font-semibold'>Blog</h3>
        </div>
      </Link>

      <div className='flex flex-col'>
        {posts
          .map(post => {
            return (
              <Link key={post.node.frontmatter.slug} to={post.node.frontmatter.slug}>
                <BlogPostExcerpt post={post.node}></BlogPostExcerpt>
              </Link>
            )
          })}
      </div>
    </Layout>
  )
}

export default PostsPage
